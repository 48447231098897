import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { TbLoader3 } from "react-icons/tb";
import { set } from 'date-fns';
import { FaRegTrashAlt } from "react-icons/fa";
import { toast } from 'sonner';

const Carrito = () => {

    const [carrito, setCarrito] = useState([])
    const [totalItems, setTotalItems] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [loading, setLoading] = useState(true);

    const updateTotalItems = () => {
        const totalQty = carrito.reduce((acc, item) => acc + item.qty, 0);
        setTotalItems(totalQty);
    };

    const updateTotals = () => {
        const totalPrice = carrito.reduce((acc, item) => acc + (item.qty * item.articulo.precio_contado), 0);
        setTotalPrice(totalPrice);
        setInterval(() => {
            setLoading(false);
        }, 1000);
    }
        
    const handleStorageChange = () => {
        const cart = localStorage.getItem('cart');
        if (cart) {
            let parsedCart;
            try {
                parsedCart = JSON.parse(cart);
            } catch (error) {
                console.error('Error parsing cart from localStorage', error);
                return;
            }
    
            if (Array.isArray(parsedCart)) {
                const updatedCarrito = [];
                parsedCart.forEach(async item => {
                    try {
                        const response = await fetch('https://www.muebleria-villarreal.com/apimv/articulosCarritoController.php?grupo=' + item.articulo.grupo + '&articulo=' + item.articulo);
                        const data = await response.json();
                        updatedCarrito.push({ articulo: data[0], qty: item.qty });
                        if (updatedCarrito.length === parsedCart.length) {
                            setCarrito(updatedCarrito);
                            setLoading(false);
                        }
                    } catch (error) {
                        console.error('There was an error!', error);
                    }
                });
            } else {
                console.error('Parsed cart is not an array');
            }
        }
        updateTotalItems();
        updateTotals();
    };

    useEffect(() => {
        const cart = localStorage.getItem('cart');
        if (cart) {
            let parsedCart;
            try {
                parsedCart = JSON.parse(cart);
            } catch (error) {
                console.error('Error parsing cart from localStorage', error);
                return;
            }
    
            if (Array.isArray(parsedCart)) {
                const updatedCarrito = [];
                parsedCart.forEach(async item => {
                    try {
                        const response = await fetch('https://www.muebleria-villarreal.com/apimv/articulosCarritoController.php?grupo=' + item.articulo.grupo + '&articulo=' + item.articulo);
                        const data = await response.json();
                        if(data.error) {
                            toast.error('No hay suficiente existencia para el producto: .' + item.articulo);
                            // Filter out the item with the matching articulo
                            cart = cart.filter(cartItem => cartItem.articulo !== item.articulo);
                            
                            // Save the updated cart back to local storage
                            localStorage.setItem('cart', JSON.stringify(cart));
                        } else {
                            updatedCarrito.push({ articulo: data[0], qty: item.qty });
                            if (updatedCarrito.length === parsedCart.length) {
                                setCarrito(updatedCarrito);
                                setLoading(false);
                            }
                        }
                    } catch (error) {
                        console.error('There was an error!', error);
                    }
                });
            } else {
                console.error('Parsed cart is not an array');
            }
        }

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        updateTotalItems();
        updateTotals();
        console.log(carrito);
    }, [carrito]);

    const handleQtyChange = (articuloId) => (event) => {
        setLoading(true);
        let newQty = event.target.value;
        console.log(newQty);
    
        // Allow the value to be an empty string
        if (newQty === "") {
            const updatedCarrito = carrito.map(item => {
                if (item.articulo.articulo === articuloId) {
                    return { ...item, qty: 1 };
                }
                return item;
            });
    
            setCarrito(updatedCarrito);
            localStorage.setItem('cart', JSON.stringify(updatedCarrito));
            window.dispatchEvent(new Event('cartUpdated'));
        } else {
            // Ensure the input is a valid integer and greater than zero
            if (isNaN(newQty) || parseInt(newQty, 10) < 1) {
                newQty = 1;
            } else {
                newQty = parseInt(newQty, 10);
            }
    
            const updatedCarrito = carrito.map(item => {
                if (item.articulo.articulo === articuloId) {
                    const maxQty = parseInt(item.articulo.exist, 10);
                    if (newQty > maxQty) {
                        newQty = maxQty;
                    }
                    return { ...item, qty: newQty };
                }
                return item;
            });
    
            const updateCarritoStorage = updatedCarrito.map(item => ({ articulo: item.articulo.articulo, qty: item.qty }));
    
            setCarrito(updatedCarrito);
            localStorage.setItem('cart', JSON.stringify(updateCarritoStorage));
            window.dispatchEvent(new Event('cartUpdated'));
        }
    };
    
    const handleQtyChangeButton = (articuloId, cantidad) => () => {
        setLoading(true);
        let newQty = cantidad;
    
        // Ensure the input is a valid integer and greater than zero
        if (!/^\d+$/.test(newQty) || parseInt(newQty, 10) < 1) {
            newQty = 1;
        } else {
            newQty = parseInt(newQty, 10);
        }
    
        const updatedCarrito = carrito.map(item => {
            if (item.articulo.articulo === articuloId) {
                const maxQty = parseInt(item.articulo.exist, 10);
                if (newQty > maxQty) {
                    newQty = maxQty;
                    toast.error('No hay suficiente existencia para este producto.');
                }
                return { ...item, qty: newQty };
            }
            return item;
        });

        const updateCarritoStorage = updatedCarrito.map(item => ({ articulo: item.articulo.articulo, qty: item.qty }));
    
        setCarrito(updatedCarrito);
        localStorage.setItem('cart', JSON.stringify(updateCarritoStorage));
        window.dispatchEvent(new Event('cartUpdated'));
    };

    // Prevent the user from entering a decimal point
    const preventDecimalInput = (event) => {
        if (event.key === '.') {
            event.preventDefault();
        }
    };

    // Attach the event listener to the input field
    document.querySelectorAll('input[type="number"]').forEach(input => {
        input.addEventListener('keydown', preventDecimalInput);
    });

    const handleDelete = (articuloId) => () => {
        const updatedCarrito = carrito.filter(item => item.articulo.articulo !== articuloId);
        setCarrito(updatedCarrito);
        const updateCarritoStorage = updatedCarrito.map(item => ({ articulo: item.articulo.articulo, qty: item.qty }));
        localStorage.setItem('cart', JSON.stringify(updateCarritoStorage));
        window.dispatchEvent(new Event('cartUpdated'));
    }

    

  return (
    <Layout>
        <section className='relative w-full px-[5vw] py-8 lg:py-10 flex flex-col lg:flex-row justify-start items-start gap-5'>
        {
            loading &&
                <div className="w-full h-full flex justify-center items-center absolute z-50 bg-white/95 top-0 left-0">
                    <TbLoader3 size={60} className='stroke-azul animate-spin' />
                </div>
        }
            <div className={`flex flex-col justify-start items-start gap-4 w-full ${carrito && carrito.length ? "lg:w-9/12" : "lg:w-full"} `}>
                {
                    carrito && carrito.length !== 0 &&
                    (
                        <h2 className='font-medio text-azul text-2xl lg:text-2xl text-left border-b-2 border-b-azul w-fit pb-1 tracking-tight px-5 flex flex-row justify-start items-start gap-3'>
                            Mi bolsa
                            <div className='size-6 bg-azul text-white flex justify-center items-center rounded-full text-xs -mt-1 font-negro'>
                                {totalItems}
                            </div>
                        </h2>

                    )
                }
                {
                    carrito && carrito.length == 0 ?
                    (
                        <div className='w-full flex flex-col justify-center items-center'>
                                <p className='text-center pt-10 text-lg lg:text-2xl text-azul font-medio'>Tu carrito está vacío.</p>
                                <p className='text-center pb-10'>Navega en muebleria-villarreal.com y agrega los productos que buscas.</p>
                                <a href="/" className='w-full lg:w-fit bg-darkGris text-center py-3 lg:py-2 px-4 text-white text-sm rounded lg:hidden'>Regresar a Tienda</a>
                        </div>
                    )
                    :
                    (
                        <table className="table-auto w-full shadow">
                            <thead className='align-middle rounded-t-xl hidden lg:table-header-group'>
                                <tr className='rounded-t-xl'>
                                    <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'></th>
                                    <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Producto</th>
                                    <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Precio</th>
                                    <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Cantidad</th>
                                    <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    carrito && carrito.length && carrito.map((item, index) => (
                                        <tr key={index} className='align-top flex lg:table-row flex-col justify-center items-center'>
                                            <td className='lg:w-44 p-2 relative hidden lg:table-cell'>
                                                <div onClick={handleDelete(item.articulo.articulo)} className='size-6 rounded-full shadow bg-rojo flex justify-center items-center text-[8px] font-negro absolute top-2 left-2 z-20 cursor-pointer transition-all'>
                                                    <FaRegTrashAlt size={12} fill='white' />
                                                </div>
                                                <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + item.articulo.departamento + "/" + item.articulo.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-8/12 lg:w-full object-cover relative z-10 mx-auto cursor-pointer' onClick={() => {
                                                    window.location.href = "/Articulo/" + item.articulo.articulo + "/" + item.articulo.grupo
                                                }} />
                                            </td>
                                            <td className='py-1 px-2 lg:p-2 hidden lg:table-cell'>
                                                <a href={"/Articulo/" + item.articulo.articulo + "/" + item.articulo.grupo}>
                                                    {item.articulo.descripcion_corta}
                                                </a>
                                            </td>
                                            <td className='py-1 px-2 lg:p-2 text-rojo font-medio min-w-32 hidden lg:table-cell'>
                                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado)}
                                            </td>
                                            <td className='py-1 px-2 lg:p-2 min-w-32 hidden lg:flex justify-start items-center'>
                                                {
                                                    item.qty > 1 ?
                                                    <button
                                                        onClick={handleQtyChangeButton(item.articulo.articulo, item.qty - 1)}
                                                        className='py-2 w-8 border border-gris/40 rounded outline-none lg:text-center text-xs text-center'
                                                    >
                                                        -
                                                    </button>
                                                    :
                                                    <button
                                                        onClick={handleDelete(item.articulo.articulo)}
                                                        className='py-2.5 w-8 border border-gris/40 rounded outline-none lg:text-center text-xs text-center flex justify-center items-center transition-all hover:bg-rojo group'
                                                    >
                                                        <FaRegTrashAlt size={12} className='group-hover:fill-white transition-all fill-dark' />
                                                    </button>
                                                }
                                                {/* <button
                                                    onClick={handleQtyChangeButton(item.articulo.articulo, item.qty - 1)}
                                                    className='py-2 w-8 border border-gris/40 rounded outline-none lg:text-center text-xs text-center'
                                                >
                                                    -
                                                </button> */}
                                                <input
                                                    type="text"
                                                    min={1}
                                                    max={item.articulo.exist}
                                                    className='p-2 border border-gris/40 rounded outline-none lg:text-center text-xs w-16 text-center'
                                                    value={item.qty}
                                                    onKeyDown={(e) => {
                                                        if (e.key === '.' || e.key === 'e' || e.key === " ") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.value = "";
                                                    }}
                                                    onChange={handleQtyChange(item.articulo.articulo)}
                                                />
                                                <button
                                                    onClick={handleQtyChangeButton(item.articulo.articulo, item.qty + 1)}
                                                    className='py-2 w-8 border border-gris/40 rounded outline-none lg:text-center text-xs text-center'
                                                >
                                                    +
                                                </button>
                                            </td>
                                            <td className='py-1 px-2 lg:p-2 text-rojo font-medio min-w-32 hidden lg:table-cell'>
                                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado * item.qty)}
                                            </td>
                                            <td className='w-full p-2 relative flex lg:hidden flex-row justify-between items-start gap-2'>
                                                <div onClick={handleDelete(item.articulo.articulo)} className='size-6 rounded-full shadow bg-rojo flex justify-center items-center text-[8px] font-negro absolute top-2 left-2 z-20 cursor-pointer transition-all'>
                                                    <FaRegTrashAlt size={12} fill='white' />
                                                </div>
                                                <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + item.articulo.departamento + "/" + item.articulo.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-full max-w-[150px] object-cover relative z-10 mx-auto' />
                                                <div className='w-1/2 flex flex-col justify-start items-start gap-2'>
                                                    <p className='text-sm font-medio'>{item.articulo.descripcion_corta}</p>
                                                    <p className='text-sm font-medio text-rojo'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado)}</p>
                                                    <div className="flex justify-start items-center">
                                                        <button
                                                            onClick={handleQtyChangeButton(item.articulo.articulo, item.qty - 1)}
                                                            className='py-1 w-8 h-[38px] border border-gris/40 rounded outline-none lg:text-center text-xs text-center'
                                                        >
                                                            -
                                                        </button>
                                                        <input
                                                            type="text"
                                                            min="1"
                                                            max={item.articulo.exist}
                                                            className='p-2 border border-gris/40 rounded outline-none lg:text-left text-sm w-16 text-center'
                                                            value={item.qty}
                                                            onChange={handleQtyChange(item.articulo.articulo)}
                                                        />
                                                        <button
                                                            onClick={handleQtyChangeButton(item.articulo.articulo, item.qty + 1)}
                                                            className='py-1 w-8 h-[38px] border border-gris/40 rounded outline-none lg:text-center text-xs text-center'
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                    <p className='text-sm font-medio text-rojo'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado * item.qty)}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    )
                }
                <a href="/" className='w-full lg:w-fit bg-darkGris text-center py-3 lg:py-2 px-4 text-white text-sm rounded hidden lg:block'>Regresar a Tienda</a>
            </div>
            {
                carrito && carrito.length ?
                <aside className="flex flex-col justify-start items-start gap-4 w-full lg:w-4/12">
                    <div className='shadow p-4 flex flex-col justify-start items-start bg-white w-full lg:mt-14 gap-2'>
                        <div className="flex w-full flex-row justify-between items-center gap-2">
                            <p className='text-sm'>Subtotal ({totalItems == 1 ? "1 producto" : totalItems + " productos"})</p>
                            <p className='text-sm'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice / 1.16)}</p>
                        </div>
                        <div className="flex w-full flex-row justify-between items-center gap-2">
                            <p className='text-sm'>IVA</p>
                            <p className='text-sm'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice - (totalPrice / 1.16))}</p>
                        </div>
                        <div className="flex w-full flex-row justify-between items-center gap-2 pb-1">
                            <p className='text-sm'>Costo de envío</p>
                            <p className='text-sm'>Gratis</p>
                        </div>
                        <div className="flex w-full flex-row justify-between items-center gap-2 pt-2 border-t border-t-gris/40">
                            <p className='text-sm font-negro'>Total (IVA incluido)</p>
                            <p className='text-sm font-negro text-rojo'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}</p>
                        </div>
                    </div>
                    {/* <p className='italic text-xs pt-3'>*Los precios aplican de acuerdo a forma de pago.</p>
                    <p className='italic text-xs pt-1'>*La fecha estimada de entrega puede variar debido a la disponibilidad de los productos, al domicilio de entrega y a la forma de pago.</p> */}
                    <p className='italic text-xs pt-3'>* Los precios que aparecen en página web son de riguroso contado.</p>
                    <p className='italic text-xs pt-1'>* ⁠Las ventas con precio a crédito solamente se realizan físicamente en cualquiera de nuestras sucursales. </p>
                    <p className='italic text-xs pt-1'>* La fecha estimada de entrega puede variar debido a la disponibilidad de los productos, al domicilio de entrega y a la forma de pago.</p>
                    <p className='italic text-xs pt-1'>* Las entregas se agendan de acuerdo al orden en que se realiza la compra. </p>
                    <p className='italic text-xs pt-1'>* Las órdenes foráneas se agendan según la disponibilidad de camiones con ruta foránea.</p>
                    <p className='italic text-xs pt-1'>* Precio exclusivo para ventas realizadas a través de la página web.</p>
                    <p className='italic text-sm pt-3 font-negro'>AYUDA EN LÍNEA</p>
                    <p className='italic text-xs pt-0'>Si tienes alguna duda con tu compra Marca: <a href="tel:+528343110729" target='_blank' className='text-link underline'>+52 834 311 0729.</a></p>
                    <p className='italic text-xs pt-0'>Horario: 09:00am a 19:00pm</p>
                    <a href="/CarritoCheckOut" className='w-full bg-link text-center py-3 lg:py-2 px-4 text-white text-sm rounded'>Comprar</a>
                    <a href="/" className='w-full lg:w-fit bg-darkGris text-center py-3 lg:py-2 px-4 text-white text-sm rounded lg:hidden'>Regresar a Tienda</a>
                </aside>
                :
                null
            }
        </section>
    </Layout>
  )
}

export default Carrito