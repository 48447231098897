import React, { useEffect, useState } from 'react'
import Footer from '../layout/Footer';
import { FaCircleCheck } from "react-icons/fa6";
import { MdLockOutline } from "react-icons/md";
import { TbLoader3 } from "react-icons/tb";
import { HiMapPin } from "react-icons/hi2";
import { MdWatchLater } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { initMercadoPago, Wallet  } from '@mercadopago/sdk-react'

import { FaCheckCircle } from "react-icons/fa";

import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Checkbox,
    Select,
    Option,
    Button,
    Tooltip
} from "@material-tailwind/react";

import { Toaster, toast } from 'sonner';
import axios from 'axios';


const initialOptions = {
    // clientId: "AcZhPnx-9ll1BXRP_aeKTIHSX7I-H6AeQxxP5P44ykgGTawDYRZlqNxA6bc7ISGtZRzIut9j604T0prr",
    clientId: "ARSlRg913gSIHJDgHHDAv3TQFQMfG7vunewPdNtmA3FwE2gA2ckXLEVLzg4FoG-lTad5La_kePLc2MDb",
    currency: "MXN",
    intent: "capture",
    locale: "es_MX",
    "disable-funding": "card"
};

const Checkout = () => {

    initMercadoPago('APP_USR-49b7d7d8-934a-4872-9a1b-1f7b364ee4d1', {
        locale: 'es-MX'
    });

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const validatePhone = (phone) => {
      const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
      return phoneRegex.test(phone);
    };

    const [carrito, setCarrito] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [terminos, setTerminos] = useState(false);
    const [requiereFactura, setRequiereFactura] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingPaypal, setLoadingPaypal] = useState(false);
    const [facturaFinalizada, setFacturaFinalizada] = useState(false);
    const [facturaError, setFacturaError] = useState(false);

    const [municipios, setMunicipios] = useState([]);
    const [municipioSelected, setMunicipioSelected] = useState(null);

    const [ciudades, setCiudades] = useState([]);
    const [ciudadSelected, setCiudadSelected] = useState(null);
    
    const [colonias, setColonias] = useState([]);
    const [coloniaSelected, setColoniaSelected] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [openModalDomicilio, setOpenModalDomicilio] = useState(false);
    const [openModalDomicilioUser, setOpenModalDomicilioUser] = useState(false);

    const [nombreRecoge, setNombreRecoge] = useState('');
    const [telefonoRecoge, setTelefonoRecoge] = useState('');
    const [correoRecoge, setCorreoRecoge] = useState('');

    const [isLogged, setIsLogged] = useState(false);

    const [direccionesUser, setDireccionesUser] = useState([]);
    const [direccionSelected, setDireccionSelected] = useState(null);

    const [preferencedId, setPreferencedId] = useState(null);

    const [rfcValue, setRfcValue] = useState('');

    // Direccion States

    const [nombre, setNombre] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [calle, setCalle] = useState('');
    const [numExt, setNumExt] = useState('');
    const [numInt, setNumInt] = useState('');
    const [entreCalle1, setEntreCalle1] = useState('');
    const [entreCalle2, setEntreCalle2] = useState('');
    const [cp, setCp] = useState('');
    const [referencias, setReferencias] = useState('');

    const [direccion, setDireccion] = useState(null);
    const [municipioSelectedUser, setMunicipioSelectedUser] = useState(null);
    const [ciudadSelectedUser, setCiudadSelectedUser] = useState(null);
    const [coloniaSelectedUser, setColoniaSelectedUser] = useState(null);

    // Factura States
    const [tipoP, setTipoP] = useState('');
    const [nombreFiscal, setNombreFiscal] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [apellidoPaternoFiscal, setApellidoPaternoFiscal] = useState('');
    const [apellidoMaternoFiscal, setApellidoMaternoFiscal] = useState('');
    const [calleFiscal, setCalleFiscal] = useState('');
    const [numExtFiscal, setNumExtFiscal] = useState('');
    const [numIntFiscal, setNumIntFiscal] = useState('');
    const [estadoFiscal, setEstadoFiscal] = useState(null);
    const [municipioFiscal, setMunicipioFiscal] = useState([]);
    const [ciudadFiscal, setCiudadFiscal] = useState('');
    const [coloniaFiscal, setColoniaFiscal] = useState('');
    const [cpFiscal, setCpFiscal] = useState('');
    const [usoCFDI, setUsoCFDI] = useState('');
    const [usoCFDISelected, setUsoCFDISelected] = useState('');
    const [estadoFiscalSelected, setEstadoFiscalSelected] = useState(null);
    const [municipioFiscalSelected, setMunicipioFiscalSelected] = useState(null);
    const [ciudadFiscalSelected, setCiudadFiscalSelected] = useState(null);
    const [coloniaFiscalSelected, setColoniaFiscalSelected] = useState(null);
    const [infoRFC, setInfoRFC] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const status = queryParams.get('error');
        if(status) {
            toast.error('Ocurrió un error al procesar tu pago, favor de intentar de nuevo.',  {
                duration: 3000
            });
        }
        fetch("https://www.muebleria-villarreal.com/apimv/getCFDIController.php")
            .then(response => response.json())
            .then(data => {
                setUsoCFDI(data.cfdiRow);
            })
        fetch("https://www.muebleria-villarreal.com/apimv/getEstadoFacturacionController.php")
            .then(response => response.json())
            .then(data => {
                setEstadoFiscal(data.estadosRow);
            })
    }, []);

    useEffect(() => {
        if (direccionSelected && direccionesUser.length) {
            const selectedDireccion = direccionesUser.find(d => d.id === direccionSelected);
            setDireccion(selectedDireccion);
            fetch("https://muebleria-villarreal.com/apimv/getDireccionSelectedCheckoutController.php?municipio=" + selectedDireccion.municipio + "&ciudad=" + selectedDireccion.ciudad + "&colonia=" + selectedDireccion.colonia)
                .then(response => response.json())
                .then(data => {
                    setColoniaSelectedUser(data.colonia[0].descripcion);
                    setMunicipioSelectedUser(data.municipio[0].descripcion);
                    setCiudadSelectedUser(data.ciudad[0].descripcion);
                })
        }
    }, [direccionSelected]);

    useEffect(() => {
        if (estadoFiscalSelected) {
            fetch("https://muebleria-villarreal.com/apimv/getMunicipioFacturacionController.php?estado=" + estadoFiscalSelected)
                .then(response => response.json())
                .then(data => {
                    setMunicipioFiscal(data.municipiosRow)
                })
        }
    }, [estadoFiscalSelected]);

    useEffect(() => {
        if (municipioFiscalSelected) {
            fetch("https://muebleria-villarreal.com/apimv/getCiudadFacturacionController.php?estado=" + estadoFiscalSelected + "&municipio=" + municipioFiscalSelected)
                .then(response => response.json())
                .then(data => {
                    setCiudadFiscal(data.ciudadesRow);
                })
        }
    }, [municipioFiscalSelected, estadoFiscalSelected]);

    useEffect(() => {
        if (ciudadFiscalSelected) {
            fetch("https://muebleria-villarreal.com/apimv/getColoniaFacturacionController.php?estado=" + estadoFiscalSelected + "&municipio=" + municipioFiscalSelected + "&ciudad=" + ciudadFiscalSelected)
                .then(response => response.json())
                .then(data => {
                    setColoniaFiscal(data.coloniasRow)
                })
        }
    }, [ciudadFiscalSelected, municipioFiscalSelected, estadoFiscalSelected]);


    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };
    const handleOpenModalDomicilio = () => {
        setOpenModalDomicilio(!openModalDomicilio);
    };
    
    const handleOpenModalDomicilioUser = () => {
        setOpenModalDomicilioUser(!openModalDomicilioUser);
    };

    const [entrega, setEntrega] = useState('');
    const [formaPago, setFormaPago] = useState('');
    
    const [tipoPersona, setTipoPersona] = useState('');

    const updateTotalItems = () => {
        const totalQty = carrito.reduce((acc, item) => acc + item.qty, 0);
        setTotalItems(totalQty);
    };

    const updateTotals = () => {
        const totalPrice = carrito.reduce((acc, item) => acc + (item.qty * item.articulo.precio_contado), 0);
        setTotalPrice(totalPrice);
    }

    useEffect(() => {
        localStorage.removeItem('direccionEntregaMV');
        localStorage.removeItem('compra_mv');
        localStorage.removeItem('infoRFC');
        const user = localStorage.getItem('eyJhbGciOiJIUzI1NiJ9');
        if (user) {
            setIsLogged(true);
            let userData = JSON.parse(user);
            fetch('https://www.muebleria-villarreal.com/apimv/checkoutDireccionesController.php?userid=' + userData.id)
            .then(response => response.json())
            .then(data => {
                setDireccionesUser(data)
            })
            .catch(error => console.error('There was an error!', error));
        }
    }, [])

    useEffect(() => {
        fetch("https://muebleria-villarreal.com/apimv/getMPPreferenceController.php?total_venta=" + totalPrice)
            .then(response => response.json())
            .then(data => {
                setPreferencedId(data);
            })
    }, [totalPrice]);
    
    useEffect(() => {
        const cart = localStorage.getItem('cart')
        if (cart) {
            JSON.parse(cart).map(async item => {
                try {
                    const response = await fetch('https://www.muebleria-villarreal.com/apimv/articulosCarritoController.php?grupo=' + item.articulo.grupo + '&articulo=' + item.articulo);
                    const data = await response.json();
                    if(data.error) {
                        toast.error('No hay suficiente existencia para el producto: .' + item.articulo);
                        // Filter out the item with the matching articulo
                        cart = cart.filter(cartItem => cartItem.articulo !== item.articulo);
                        
                        // Save the updated cart back to local storage
                        localStorage.setItem('cart', JSON.stringify(cart));

                    } else {
                        setCarrito(prevState => [...prevState, { articulo: data[0], qty: item.qty }]);
                        console.log('data', data);
                        setInterval(() => {
                            setLoading(false);
                        }, 500);
                    }
                } catch (error) {
                    console.error('There was an error!', error);
                    window.location.href = '/carrito';
                }
            });
        } else {
            window.location.href = '/carrito';
        }
    }, [])

    useEffect(() => {
        updateTotalItems();
        updateTotals();
    }, [carrito]);

    useEffect(() => {
        fetch('https://www.muebleria-villarreal.com/apimv/getMunicipioController.php')
            .then(response => response.json())
            .then(data => setMunicipios(data))
            .catch(error => console.error('There was an error!', error));
    }, [])

    useEffect(() => {
        if (municipioSelected) {
            fetch('https://www.muebleria-villarreal.com/apimv/getCiudadController.php?municipio=' + municipioSelected)
              .then(response => response.json())
              .then(data => {
                setCiudades(data);
                console.log(data);
            })
              .catch(error => console.error('There was an error!', error));
        }
    }, [municipioSelected]);
  
    useEffect(() => {
        if (ciudadSelected) {
            fetch('https://www.muebleria-villarreal.com/apimv/getColoniasController.php?municipio=' + municipioSelected + '&ciudad=' + ciudadSelected)
                .then(response => response.json())
                .then(data => {
                    setColonias(data)
                    console.log(data);
                })
                .catch(error => console.error('There was an error!', error));
        }
    }, [ciudadSelected, municipioSelected]);

    const handlePF = (e) => {
        const rfc = e.target.value.toUpperCase();
        setRfcValue(rfc);
        if (rfc.length === 13) {
            setLoading(true);
            fetch('https://www.muebleria-villarreal.com/apimv/getRFCController.php?rfc=' + rfc)
                .then(response => response.json())
                .then(data => {
                    if(data.result === 'successful') {
                        setInfoRFC(data.rfcInfo);
                        localStorage.setItem('infoRFC', JSON.stringify(data.rfcInfo));
                        setFacturaFinalizada(true);
                        setFacturaError(false);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        toast.success('RFC encontrado',  {
                            duration: 3000
                        });
                    } else {
                        console.log("RFC no encontrado");
                        localStorage.removeItem('infoRFC');
                        setFacturaFinalizada(false);
                        setFacturaError(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        setTipoP('F');
                        toast.error('No se encontraron sus datos en el sistema, favor de ingresar su información de facturación.',  {
                            duration: 3000
                        });
                    }
                })
                .catch(error => console.error('There was an error!', error));
        } else {
            const truncatedRfc = rfc.length > 13 ? rfc.substring(0, 13) : rfc;
            setRfcValue(truncatedRfc);
            console.log("RFC no valido");
            setFacturaFinalizada(false);
            setFacturaError(false);
        }
    };

    const handlePM = (e) => {
        const rfc = e.target.value.toUpperCase();
        setRfcValue(rfc);
        if (rfc.length === 12) {
            setLoading(true);
            fetch('https://www.muebleria-villarreal.com/apimv/getRFCController.php?rfc=' + rfc)
                .then(response => response.json())
                .then(data => {
                    if(data.result === 'successful') {
                        setInfoRFC(data.rfcInfo);
                        localStorage.setItem('infoRFC', JSON.stringify(data.rfcInfo));
                        setFacturaFinalizada(true);
                        setFacturaError(false);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        toast.success('RFC encontrado',  {
                            duration: 3000
                        });
                    } else {
                        console.log("RFC no encontrado");
                        localStorage.removeItem('infoRFC');
                        setFacturaFinalizada(false);
                        setFacturaError(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        setTipoP('M');
                        toast.error('No se encontraron sus datos en el sistema, favor de ingresar su información de facturación.',  {
                            duration: 3000
                        });
                    }
                })
                .catch(error => console.error('There was an error!', error));
        } else {
            console.log("RFC no valido");
            setFacturaFinalizada(false);
            setFacturaError(false);
        }
    };

    const handleDomicilioEntrega = () => {
        localStorage.setItem('direccionEntregaMV', JSON.stringify({
            nombre: nombre,
            apellidoPaterno: apellidoPaterno,
            apellidoMaterno: apellidoMaterno,
            telefono: telefono,
            email: email,
            calle: calle,
            numExt: numExt,
            numInt: numInt,
            entreCalle1: entreCalle1,
            entreCalle2: entreCalle2,
            cp: cp,
            municipio: municipioSelected,
            ciudad: ciudadSelected,
            colonia: coloniaSelected,
            referencias: referencias
        }));
    };
    
    const handleDomicilioEntregaUser = async () => {
        const user = localStorage.getItem('eyJhbGciOiJIUzI1NiJ9');
        let userData = JSON.parse(user);
        localStorage.setItem('direccionEntregaMV', JSON.stringify({
            nombre: direccion.nombre_contacto,
            apellidoPaterno: direccion.paterno,
            apellidoMaterno: direccion.materno,
            telefono: direccion.telefono,
            email: userData.email,
            calle: direccion.calle,
            numExt: direccion.num_ext,
            numInt: direccion.num_int,
            entreCalle1: direccion.entre_calle,
            entreCalle2: direccion.y_calle,
            cp: direccion.cp,
            municipio: direccion.municipio,
            ciudad: direccion.ciudad,
            colonia: direccion.colonia,
            referencias: direccion.referencias
        }));
    };

    const handleDomicilioEntregaRecoger = () => {
        const user = localStorage.getItem('eyJhbGciOiJIUzI1NiJ9');
        let userData = JSON.parse(user);
        localStorage.setItem('direccionEntregaMV', JSON.stringify({
            nombre: userData ? userData.nombre : nombreRecoge,
            apellidoPaterno: userData ? userData.apellido_paterno : '',
            apellidoMaterno: userData ? userData.apellido_materno : '',
            telefono: telefonoRecoge,
            email: userData ? userData.email : correoRecoge,
            calle: 'Tenochtitlan, Centro, 87024 Cdad. Victoria, Tamps.',
            numExt: '225',
            numInt: '',
            entreCalle1: 'N/A',
            entreCalle2: 'N/A',
            cp: '87024',
            municipio: '41',
            ciudad: '1',
            colonia: '28',
            referencias: 'Recoger en Sucursal - ' + nombreRecoge + '. Tel: ' + telefonoRecoge + '. Correo: ' + correoRecoge + '.'
        }))
    };

    const handlePayment = async () => {
        setLoadingPaypal(true);
        localStorage.setItem("compra_mv", JSON.stringify({totalPrice: totalPrice, formaPago: "Santander"}))
        const getDireccion = localStorage.getItem('direccionEntregaMV');
        const email = JSON.parse(getDireccion).email;
        try {
          const response = await axios.get('https://muebleria-villarreal.com/apimv/generarReferenciaSantanderController.php?total=' + totalPrice + '&correo=' + email);
          console.log('Response data:', response.data); // Log the raw response data
          if (response.data.includes("success")) {
            var jsonData = parseXML(response.data);
            console.log('Parsed JSON data:', jsonData); // Log the parsed JSON data
            var url = findNbUrl(jsonData);
            if (url) {
              window.open(url, '_self');
            } else {
              console.error('URL not found in the response');
            }
          }
        } catch (error) {
          console.error('Error:', error);
          if (error.response) {
            console.log('Response data:', error.response.data);
            console.log('Response status:', error.response.status);
            console.log('Response headers:', error.response.headers);
          }
        }
      };
    
      const parseXML = (xmlString) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
        const json = xmlToJson(xmlDoc);
        return json;
      };
    
      const xmlToJson = (xml) => {
        let obj = {};
        if (xml.nodeType === 1) { // element
          if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (let j = 0; j < xml.attributes.length; j++) {
              const attribute = xml.attributes.item(j);
              obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
          }
        } else if (xml.nodeType === 3) { // text
          obj = xml.nodeValue;
        }
    
        if (xml.hasChildNodes()) {
          for (let i = 0; i < xml.childNodes.length; i++) {
            const item = xml.childNodes.item(i);
            const nodeName = item.nodeName;
            if (typeof (obj[nodeName]) === "undefined") {
              obj[nodeName] = xmlToJson(item);
            } else {
              if (typeof (obj[nodeName].push) === "undefined") {
                const old = obj[nodeName];
                obj[nodeName] = [];
                obj[nodeName].push(old);
              }
              obj[nodeName].push(xmlToJson(item));
            }
          }
        }
        return obj;
      };
    
      const findNbUrl = (jsonData) => {
        console.log('Navigating JSON data:', jsonData); // Log the JSON data being navigated
        if (jsonData && jsonData.P_RESPONSE && jsonData.P_RESPONSE.nb_url && jsonData.P_RESPONSE.nb_url['#text']) {
          return jsonData.P_RESPONSE.nb_url['#text'];
        }
        return null;
      };

  return (
    <main>
        <Toaster richColors closeButton position="top-right" />
        <Dialog size='lg' open={openModal} handler={handleOpenModal}>
            <DialogHeader className='bg-azul text-white text-lg font-medio rounded-t-lg'>Recoger en Tienda</DialogHeader>
            <DialogBody>
                <h2 className='lg:text-xl font-medio'>Bodega</h2>
                <div className="flex flex-row justify-start items-center gap-1 pt-2">
                    <HiMapPin size={20} className='fill-dark' />
                    <p className='text-sm lg:text-base'>Fracc. Teocaltiche</p>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 pt-2">
                    <MdWatchLater size={20} className='fill-dark' />
                    <p className='text-sm lg:text-base'>9:00 am a 8:00 pm de Lunes a Sábado y Domingos de 11:00 am a 7:00 pm</p>
                </div>
                <div className="flex flex-row justify-start items-center gap-1 pt-2">
                    <FaPhone size={16} className='fill-dark mr-1' />
                    <p className='text-sm lg:text-base'>Tel. (834) 305 91 08</p>
                </div>
                <br />
                <h2 className='lg:text-lg font-medio text-azul pb-3'>INFORMACIÓN DE QUIEN RECOGE</h2>
                <div className="flex flex-col justify-start items-start gap-3">
                    <Input onChange={(e) => setNombreRecoge(e.target.value)} value={nombreRecoge} type="text" color="black"  outline={true} label="Nombre de quien Recibe" />
                    <Input onChange={(e) => setTelefonoRecoge(e.target.value)} value={telefonoRecoge} type="tel" color="black"  outline={true} label="Teléfono Celular" />
                    <Input onChange={(e) => setCorreoRecoge(e.target.value)} value={correoRecoge} type="email" color="black"  outline={true} label="Correo Electrónico" />
                </div>
                <br />
                <small className='italic leading-none'>* La fecha estimada de entrega puede variar debido a la disponibilidad de los productos y a la forma de pago.</small>
            </DialogBody>
            <DialogFooter>
                <button 
                    onClick={() => {
                        setOpenModal(false);
                        setEntrega('tienda');
                        handleDomicilioEntregaRecoger();
                    }} 
                    className={
                        nombreRecoge === '' || telefonoRecoge === "" || correoRecoge === "" ?
                        'bg-azul text-white py-2 px-5 rounded font-medio hover:bg-azul/80 transition-all opacity-50'
                        :
                        'bg-azul text-white py-2 px-5 rounded font-medio hover:bg-azul/80 transition-all'
                    }
                    disabled={nombreRecoge === '' || telefonoRecoge === "" || correoRecoge === "" ? true : false}
                    >Confirmar</button>
            </DialogFooter>
        </Dialog>
        <Dialog size='lg' open={openModalDomicilio} handler={handleOpenModalDomicilio}>
            <DialogHeader className='bg-azul text-white text-lg font-medio rounded-t-lg'>Selecciona una direccion de entrega</DialogHeader>
            <DialogBody className='overflow-y-scroll h-[60vh]'>
                <h2 className='lg:text-xl font-medio'>Información del cliente</h2>
                <div className="flex flex-col justify-start items-start gap-3 pt-3">
                    <Input type="text" color="black"  outline={true} label="Nombre*" value={nombre} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setNombre("");
                        } else {
                            setNombre(e.target.value)
                        }
                    }} />
                    <Input type="text" color="black"  outline={true} label="Apellido Paterno*" value={apellidoPaterno} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setApellidoPaterno("");
                        } else {
                            setApellidoPaterno(e.target.value)
                        }
                    }} />
                    <Input type="text" color="black"  outline={true} label="Apellido Materno" value={apellidoMaterno} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setApellidoMaterno("");
                        } else {
                            setApellidoMaterno(e.target.value)
                        }
                    }} />
                    <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                        <Input type="tel" color="black"  outline={true} label="Teléfono*" value={telefono} onKeyDown={(e) => {
                            if (e.key === 'e' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',' || e.key === 'Space') {
                                e.preventDefault();
                            }
                        }} onChange={(e) => {
                        let value = e.target.value;
                        let regex = /^[0-9\b]+$/;
                        if (value === '' || regex.test(value)) {
                            setTelefono(value);
                        }}} />
                        <Input type="email" color="black"  outline={true} label="Correo Electrónico*" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <br />
                <h2 className='lg:text-xl font-medio'>Domicilio de entrega</h2>
                <div className="flex flex-col justify-start items-start gap-3 pt-3">
                    <Input type="text" color="black"  outline={true} label="Calle*" value={calle} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setCalle("");
                        } else {
                            setCalle(e.target.value)
                        }
                    }} />
                    <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                        <Input type="text" color="black"  outline={true} label="N° Exterior*" value={numExt} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setNumExt("");
                        } else {
                            setNumExt(e.target.value)
                        }
                    }} />
                        <Input type="text" color="black"  outline={true} label="N° Interior" value={numInt} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setNumInt("");
                        } else {
                            setNumInt(e.target.value)
                        }
                    }} />
                    </div>
                    <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                        <Input type="text" color="black"  outline={true} label="Entre Calle 1" value={entreCalle1} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setEntreCalle1("");
                        } else {
                            setEntreCalle1(e.target.value)
                        }
                    }} />
                        <Input type="text" color="black"  outline={true} label="Entre Calle 2" value={entreCalle2} onChange={(e) => {
                        let value = e.target.value;
                        if(value === ' ') {
                            setEntreCalle2("");
                        } else {
                            setEntreCalle2(e.target.value)
                        }
                    }} />
                    </div>
                    <div className="flex flex-col lg:flex-row w-full justify-start items-start gap-3">
                        <Input type="text" color="black"  outline={true} label="C.P.*" value={cp} onChange={(e) => setCp(e.target.value)} />
                        <div className='w-full flex flex-col gap-3'>
                            <Input type="text" color="black"  outline={true} label="Estado*" value="Tamaulipas" disabled />
                            <p className='text-xs italic text-azul underline'>Los envíos de nuestros artículos son exclusivamente a Tamaulipas</p>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                        <select className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' label='Municipio*' value={municipioSelected} onChange={(e) => {
                            setMunicipioSelected(e.target.value)
                            setCiudadSelected(null)
                            setColoniaSelected(null)
                        }}>
                            <option value="" disabled selected>Seleccionar Municipio</option>
                            {
                                municipios && municipios.length && municipios.map((municipio, index) => (
                                    <option key={index} value={municipio.municipio}>{municipio.descripcion}</option>
                                ))
                            }
                        </select>
                        <select className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' label='Ciudad*' id="selectCiudadModal" value={ciudadSelected} onChange={(e) => {
                            setCiudadSelected(e.target.value)
                            setColoniaSelected(null)
                        }} disabled={municipioSelected ? false : true}>
                            <option value="" disabled selected>Seleccionar Ciudad</option>
                            {
                                ciudades && ciudades.length && ciudades.map((ciudad, index) => (
                                    <option key={index} value={ciudad.ciudad}>{ciudad.descripcion}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                        <select className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' label='Colonia*' value={coloniaSelected} onChange={(e) => {
                            setColoniaSelected(e.target.value)
                            console.log(e.target.value);
                        }} disabled={ciudadSelected ? false : true}>
                            <option value="" disabled selected>Seleccionar Colonia</option>
                            {
                                colonias && colonias.length && ciudadSelected !== "" && colonias.map((colonia, index) => (
                                    <option key={index} value={colonia.colonia}>{colonia.descripcion}</option>
                                ))
                            }
                        </select>
                        <Input type="text" color="black"  outline={true} label="Referencia*" value={referencias} onChange={(e) => setReferencias(e.target.value)}  />
                    </div>
                </div>
                <br />
                <small className='italic leading-none'>* Recuerda que esta dirección sólo será utilizada para la entrega.</small>
            </DialogBody>
            <DialogFooter>
                <Button
                disabled={
                    nombre == "" || nombre.length < 3 || apellidoPaterno == "" || apellidoPaterno.length < 3 || telefono == "" || telefono.length !== 10 || email == "" || !validateEmail(email) || calle == "" || calle.length < 3 || numExt == "" || numExt.length < 1 || cp == "" || cp.length !== 5 || municipioSelected == null || ciudadSelected == null || coloniaSelected == null || referencias == "" || referencias.length < 3
                }
                onClick={() => {
                    setOpenModalDomicilio(false);
                    setEntrega('domicilio');
                    handleDomicilioEntrega();
                }} className='bg-azul text-white rounded font-medio hover:bg-azul/80 transition-all'>Confirmar</Button>
            </DialogFooter>
        </Dialog>

        <Dialog size='lg' open={openModalDomicilioUser} handler={handleOpenModalDomicilioUser}>
            <DialogHeader className='bg-azul text-white text-lg font-medio rounded-t-lg'>Selecciona una direccion de entrega</DialogHeader>
            <DialogBody className='overflow-y-scroll h-[60vh]'>
                <h2 className='lg:text-xl font-medio'>TUS DIRECCIONES GUARDADAS:</h2>
                <div className="flex flex-col justify-start items-start gap-3 pt-3">
                    <Select label='Selecciona una dirección' onChange={(e) => setDireccionSelected(e)}>
                        {
                            direccionesUser && direccionesUser.length && direccionesUser.map((direccion, index) => (
                                <Option key={index} value={direccion.id}>{direccion.nombre_direccion}</Option>
                            ))
                        }
                    </Select>
                </div>
                <br />
                <h2 className='lg:text-xl font-medio'>Domicilio de entrega</h2>
                {direccion && (
                    <div className="flex flex-col justify-start items-start gap-3 pt-3">
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="Quien recibe" value={`${direccion.nombre_contacto} ${direccion.paterno} ${direccion.materno}`} disabled />
                            <Input type="text" color="black" outline={true} label="Telefono de contacto:" value={direccion.telefono} disabled />
                        </div>
                        <Input type="text" color="black" outline={true} label="Calle" value={direccion.calle} disabled />
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="N° Exterior" value={direccion.num_ext} disabled />
                            <Input type="text" color="black" outline={true} label="N° Interior" value={direccion.num_int} disabled />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="Entre Calle" value={direccion.entre_calle} disabled />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="C.P." value={direccion.cp} disabled />
                            <Input type="text" color="black" outline={true} label="Estado" value="Tamaulipas" disabled />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="Municipio" value={municipioSelectedUser} disabled />
                            <Input type="text" color="black" outline={true} label="Ciudad" value={ciudadSelectedUser} disabled />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="Colonia" value={coloniaSelectedUser} disabled />
                            <Input type="text" color="black" outline={true} label="Referencia" value={direccion.referencias} disabled />
                        </div>
                    </div>
                )}
                <small className='italic leading-none'>* Recuerda que esta dirección sólo será utilizada para la entrega.</small>
            </DialogBody>
            <DialogFooter>
                <Button onClick={() => {
                    window.location.href = '/cliente';
                }} className='bg-gris text-white rounded font-medio hover:bg-azul/80 transition-all mr-2'>Otra Dirección</Button>
                <Button 
                    onClick={() => {
                        setOpenModalDomicilioUser(false);
                        setEntrega('domicilio');
                        handleDomicilioEntregaUser();
                    }}
                    className='bg-azul text-white rounded font-medio hover:bg-azul/80 transition-all'
                    disabled={
                        direccionSelected === null ? true : false
                    }
                >Confirmar</Button>
            </DialogFooter>
        </Dialog>

        <nav className='w-full bg-azul flex flex-row justify-between items-center px-5 py-4'>
            <a href="/"><img src="/images/logoBlanco.png" alt="MV Logo" className='h-10' /></a>
            <a href='/carrito' className='bg-rojo py-2 px-3 rounded text-white text-sm'>Regresar</a>
        </nav>
        <section className='container mx-auto pt-5 pb-10 px-4 flex flex-col lg:flex-row justify-between items-start gap-10 relative'>
            {
                loading &&
                    <div className="w-full h-full flex justify-center items-center absolute z-50 bg-white/95 top-0 left-0">
                        <TbLoader3 size={60} className='stroke-azul animate-spin' />
                    </div>
            }
            {
                loadingPaypal &&
                    <div className="w-full h-full flex justify-center items-center absolute z-50 bg-white/95 top-0 left-0">
                        <TbLoader3 size={60} className='stroke-azul animate-spin' />
                    </div>
            }
            <div className="w-full lg:w-8/12">
                <h1 className='font-medio text-2xl'>Confirma tu compra</h1>
                <br />
                <h2 className='font-medio text-xl text-azul uppercase'>1. Entrega</h2>
                <div className="flex flex-col lg:flex-row justify-between items-center gap-2 w-full pt-3">
                    <button onClick={isLogged ? handleOpenModalDomicilioUser : handleOpenModalDomicilio} className={entrega == "domicilio" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:bg-azul hover:text-white transition-all w-full'}>A tu domicilio</button>
                    <button onClick={handleOpenModal} className={entrega == "tienda" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:bg-azul hover:text-white transition-all w-full'}>Recoger en Tienda</button>
                </div>
                <br />
                <h2 className='font-medio text-xl text-azul uppercase'>2. FACTURACIÓN</h2>
                <div className='w-full p-4 rounded-lg bg-[#fff3cd] border border-[#ffecb5] my-4'>
                    <p className='text-sm text-[#664d03]'>¿Requieres una factura?</p>
                    <br />
                    <p className='text-sm text-[#664d03]'>Tu factura será enviada al correo proporcionado en el paso anterior.</p>
                </div>
                <div className='checkboxAzul'>
                  <Checkbox checked={requiereFactura} onChange={() => {
                    setRequiereFactura(!requiereFactura);
                    setTipoPersona('');
                    setFacturaFinalizada(false);
                  }} color='blue' label={
                    <span className='text-sm'>Requiero factura</span>
                  } />
                </div>
                {
                    requiereFactura &&
                    <>
                        <h2 className='font-medio text-base text-azul'>Tipo de Persona</h2>
                        <div className='flex flex-col lg:flex-row justify-between items-center gap-2 w-full pt-3'>
                            <button onClick={() => {
                                setTipoPersona("fisica")
                                setRfcValue('');
                                setFacturaError(false)
                                setFacturaFinalizada(false);
                                setEstadoFiscalSelected(null);
                                setMunicipioFiscalSelected(null);
                                setCiudadFiscalSelected(null);
                                setColoniaFiscalSelected(null);
                            }} className={tipoPersona == 'moral' ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:text-white hover:bg-azul/80 transition-all w-full opacity-50' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white hover:bg-azul/80 transition-all w-full'}>Fisica</button>
                            <button onClick={() => {
                                setTipoPersona("moral")
                                setRfcValue('');
                                setFacturaError(false)
                                setFacturaFinalizada(false);
                                setEstadoFiscalSelected(null);
                                setMunicipioFiscalSelected(null);
                                setCiudadFiscalSelected(null);
                                setColoniaFiscalSelected(null);
                            }} className={tipoPersona == 'fisica' ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:text-white hover:bg-azul/80 transition-all w-full opacity-50' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white hover:bg-azul/80 transition-all w-full'}>Moral</button>
                        </div>
                        {
                            tipoPersona == 'fisica' ?
                            <>
                                <h3 className='font-medio text-base text-azul pt-3 pb-2'>Persona Fisica</h3>
                                <Input type="text" color="black"  outline={true} label="RFC" onChange={handlePF} value={rfcValue} icon={facturaFinalizada ? <FaCheckCircle className='fill-azul uppercase' /> : null} />
                            </>
                            :
                            tipoPersona == 'moral' ?
                            <>
                                <h3 className='font-medio text-base text-azul pt-3 pb-2'>Persona Moral</h3>
                                <Input type="text" color="black"  outline={true} label="RFC" onChange={handlePM} value={rfcValue} icon={facturaFinalizada ? <FaCheckCircle className='fill-azul uppercase' /> : null} />
                            </>
                            :
                            null
                        }
                    </>
                }
                {
                    facturaError &&
                    <div className="flex flex-col justify-start items-start gap-3 pt-3">
                        {
                            tipoP == 'F' ?
                            <>
                                <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                                    <Input type="text" color="black" outline={true} label="Nombre *" value={nombreFiscal} onChange={(e) => {
                                        const value = e.target.value;
                                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                                        if(filteredValue === ' ') {
                                            setNombreFiscal("");
                                        } else {
                                            setNombreFiscal(filteredValue);
                                        }
                                    }} />
                                </div>
                                <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                                    <Input type="text" color="black" outline={true} label="Apellido Paterno*" value={apellidoPaternoFiscal} onChange={(e) => {
                                        const value = e.target.value;
                                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                                        if(filteredValue === ' ') {
                                            setApellidoPaternoFiscal("");
                                        } else {
                                            setApellidoPaternoFiscal(filteredValue);
                                        }
                                    }}  />
                                    <Input type="text" color="black" outline={true} label="Apellido Materno" value={apellidoMaternoFiscal} onChange={(e) => {
                                        const value = e.target.value;
                                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                                        if(filteredValue === ' ') {
                                            setApellidoMaternoFiscal("");
                                        } else {
                                            setApellidoMaternoFiscal(filteredValue);
                                        }
                                    }} />
                                </div>
                            </>
                            :
                            <>
                                <Input type="text" color="black" outline={true} label="Razón Social *" value={razonSocial} onChange={(e) => {
                                        const value = e.target.value;
                                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                                        if(filteredValue === ' ') {
                                            setRazonSocial("");
                                        } else {
                                            setRazonSocial(filteredValue);
                                        }
                                    }} />
                            </>
                        }
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="Calle*" value={calleFiscal} onChange={(e) => {
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                                if(filteredValue === ' ') {
                                    setCalleFiscal("");
                                } else {
                                    setCalleFiscal(filteredValue);
                                }
                            }} />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input type="text" color="black" outline={true} label="N° Exterior*" value={numExtFiscal} onChange={(e) => {
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, ''); // Allow only numbers
                                setNumExtFiscal(filteredValue);
                            }} />
                            <Input type="text" color="black" outline={true} label="N° Interior" value={numIntFiscal} onChange={(e) => {
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, ''); // Allow only numbers
                                setNumIntFiscal(filteredValue);
                            }} />
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <select className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' value={estadoFiscalSelected} onChange={(e) => {
                                setEstadoFiscalSelected(e.target.value);
                                setMunicipioFiscalSelected(null);
                                setCiudadFiscalSelected(null);
                                setColoniaFiscalSelected(null);
                            }}>
                                <option value='' disabled selected>Selecciona un estado</option>
                                {
                                    estadoFiscal && estadoFiscal.length && estadoFiscal.map((estado, index) => (
                                        <option key={index} value={estado.estado}>{estado.descripcion}</option>
                                    ))
                                }
                            </select>
                            <select disabled={estadoFiscalSelected == null} className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' value={municipioFiscalSelected} onChange={(e) => {
                                setMunicipioFiscalSelected(e.target.value);
                                setCiudadFiscalSelected(null);
                                setColoniaFiscalSelected(null);
                            }}>
                                <option value='' disabled selected>Selecciona un municipio</option>
                                {
                                    municipioFiscal && municipioFiscal.length && municipioFiscal.map((municipio, index) => (
                                        <option key={index} value={municipio.municipio}>{municipio.descripcion}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <select disabled={municipioFiscalSelected == null} className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' value={ciudadFiscalSelected} onChange={(e) => {
                                setCiudadFiscalSelected(e.target.value);
                                setColoniaFiscalSelected(null);
                            }}>
                                <option value='' disabled selected>Selecciona una ciudad</option>
                                {
                                    ciudadFiscal && ciudadFiscal.length && ciudadFiscal.map((ciudad, index) => (
                                        <option key={index} value={ciudad.ciudad}>{ciudad.descripcion}</option>
                                    ))
                                }
                            </select>
                            <select disabled={ciudadFiscalSelected == null} className='peer w-full h-full bg-transparent font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 placeholder:opacity-0 focus:placeholder:opacity-100 text-sm px-3 py-2.5 rounded-[7px] text-black border-black focus:border-black' value={coloniaFiscalSelected} onChange={(e) => {
                                setColoniaFiscalSelected(e.target.value);
                                console.log(e.target.value)
                            }}>
                                <option value='' disabled selected>Selecciona una colonia</option>
                                {
                                    coloniaFiscal && coloniaFiscal.length && coloniaFiscal.map((colonia, index) => (
                                        <option key={index} value={colonia.colonia}>{colonia.descripcion}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-3">
                            <Input maxLength={5} type="text" color="black" outline={true} label="C.P.*" value={cpFiscal} onChange={(e) => {
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, ''); // Allow only numbers
                                setCpFiscal(filteredValue);
                            }} />
                            <Select label='Uso CFDI*' value={usoCFDISelected} onChange={(e) => setUsoCFDISelected(e)}>
                                {
                                    usoCFDI && usoCFDI.length && usoCFDI.map((uso, index) => (
                                        <Option key={index} value={uso.uso_cfdi}>{uso.descripcion}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        {
                            tipoP == 'F' ?
                            <Button 
                            disabled={
                                nombreFiscal == "" || nombreFiscal.length < 3 || apellidoPaternoFiscal == "" || apellidoPaternoFiscal.length < 3 || calleFiscal == "" || calleFiscal.length < 3 || numExtFiscal == "" || numExtFiscal.length < 1 || cpFiscal == "" || cpFiscal.length !== 5 || estadoFiscalSelected == null || municipioFiscalSelected == null || ciudadFiscalSelected == null || coloniaFiscalSelected == null || usoCFDISelected == ""
                            }
                            onClick={() => {
                                setFacturaFinalizada(true);
                                setFacturaError(false);
                                const rfcInfo = {
                                    rfc: rfcValue,
                                    nombre: nombreFiscal,
                                    apellidoPaterno: apellidoPaternoFiscal,
                                    apellidoMaterno: apellidoMaternoFiscal,
                                    calle: calleFiscal,
                                    numExt: numExtFiscal,
                                    numInt: numIntFiscal,
                                    estado: estadoFiscalSelected,
                                    municipio: municipioFiscalSelected,
                                    ciudad: ciudadFiscalSelected,
                                    colonia: coloniaFiscalSelected,
                                    cp: cpFiscal,
                                    usoCFDI: usoCFDISelected
                                }
                                localStorage.setItem('infoRFC', JSON.stringify(rfcInfo));
                                toast.success("RFC Guardado Correctamente");
                            }} className='w-full bg-azul'>Guardar RFC</Button>
                            :
                            <Button
                            disabled={
                                razonSocial == "" || razonSocial.length < 3 || calleFiscal == "" || calleFiscal.length < 3 || numExtFiscal == "" || numExtFiscal.length < 1 || cpFiscal == "" || cpFiscal.length !== 5 || estadoFiscalSelected == null || municipioFiscalSelected == null || ciudadFiscalSelected == null || coloniaFiscalSelected == null || usoCFDISelected == ""
                            }
                            onClick={() => {
                                setFacturaFinalizada(true);
                                setFacturaError(false);
                                toast.success("RFC Guardado Correctamente");
                            }} className='w-full bg-azul'>Guardar RFC</Button>
                        }
                    </div>
                }
                <br />
                <h2 className='font-medio text-xl text-azul uppercase'>3. FORMA DE PAGO</h2>
                <div className="flex flex-col lg:flex-row justify-between items-center gap-2 w-full pt-3">
                    <button onClick={() => setFormaPago("tarjeta")} className={formaPago == "tarjeta" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:bg-azul hover:text-white transition-all w-full'}>Tarjeta Crédito/Débito</button>
                    {/* <Tooltip content="Proximamente" animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                    }}>
                        <button disabled className={formaPago == "tarjeta" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark transition-all w-full opacity-50 cursor-not-allowed'}>Tarjeta Crédito/Débito</button>
                    </Tooltip> */}
                    <button onClick={() => setFormaPago("paypal")} className={formaPago == "paypal" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:bg-azul hover:text-white transition-all w-full'}>Paypal</button>
                    <button onClick={() => setFormaPago("mercadopago")} className={formaPago == "mercadopago" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:bg-azul hover:text-white transition-all w-full'}>MercadoPago</button>
                    <Tooltip content={
                        <span className='text-[10px]'>La venta está sujeta a la disponibilidad de existencias al momento de confirmar el pago</span>
                    } animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                    }}>
                        <button onClick={() => setFormaPago("transfer")} className={formaPago == "transfer" ? 'text-xs p-3 flex-1 shadow rounded font-medio bg-azul text-white transition-all w-full' : 'text-xs p-3 flex-1 shadow rounded font-medio bg-white text-dark hover:bg-azul hover:text-white transition-all w-full'}>Transferencia</button>
                    </Tooltip>
                </div>
                <br />
                <h2 className='font-medio text-xl text-azul uppercase'>4. RESUMEN DE COMPRA</h2>
                <table className="table-auto w-full shadow mt-3">
                    <thead className='align-middle rounded-t-xl hidden lg:table-header-group'>
                        <tr className='rounded-t-xl'>
                            <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'></th>
                            <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Producto</th>
                            <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Precio</th>
                            <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Cantidad</th>
                            <th className='text-left font-negro border-b border-b-gris/40 py-2 px-2'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            carrito && carrito.length && carrito.map((item, index) => (
                                <tr key={index} className='align-top flex lg:table-row flex-col justify-center items-center'>
                                    <td className='lg:w-44 p-2 relative align-middle'>
                                        <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + item.articulo.departamento + "/" + item.articulo.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-8/12 lg:w-full object-cover relative z-10 mx-auto' />
                                    </td>
                                    <td className='py-1 px-2 lg:p-2 align-middle text-center lg:text-left'>
                                        {item.articulo.descripcion_corta}
                                    </td>
                                    <td className='py-1 px-2 lg:p-2 text-rojo font-medio min-w-32 align-middle text-center lg:text-left'>
                                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado)}
                                    </td>
                                    <td className='py-1 px-2 lg:p-2 min-w-32 align-middle text-center lg:text-left'>
                                        {item.qty}
                                    </td>
                                    <td className='py-1 px-2 lg:p-2 text-rojo font-medio min-w-32 align-middle text-center lg:text-left'>
                                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado * item.qty)}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <aside className='w-full lg:w-4/12 sticky top-4 flex flex-col justify-start items-start gap-3'>
                <div className='shadow p-4 flex flex-col justify-start items-start gap-3'>
                    <div className="flex flex-row justify-between items-center w-full">
                        <h3 className='text-sm'>Subtotal</h3>
                        <p className='text-sm'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}</p>
                    </div>
                    <div className="flex flex-row justify-between items-center w-full pb-3 border-b border-b-gray-200">
                        <h3 className='text-sm'>Gastos de Envío</h3>
                        <p className='text-sm'>Gratis</p>
                    </div>
                    <div className="flex flex-row justify-between items-center w-full">
                        <h3 className='text-base font-medio '>Total (IVA incluido)</h3>
                        <p className='text-base font-medio text-rojo'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}</p>
                    </div>
                    <span className='text-xs italic font-base text-gray-500'>*La fecha estimada de entrega puede variar debido a la disponibilidad de los productos, al domicilio de entrega y a la forma de pago.</span>
                    <div className="flex flex-row justify-between items-center w-full pt-3">
                        <h3 className='text-base font-medio '>Entrega</h3>
                        <p className=''><FaCircleCheck size={20} className={entrega !== "" ? 'fill-azul' : 'fill-darkGris/30'} /></p>
                    </div>
                    <div className="flex flex-row justify-between items-center w-full">
                        <h3 className='text-base font-medio '>Forma de Pago</h3>
                        <p className=''><FaCircleCheck size={20} className={formaPago !== "" ? 'fill-azul' : 'fill-darkGris/30'} /></p>
                    </div>
                    {
                        requiereFactura && (
                            <div className="flex flex-row justify-between items-center w-full">
                                <h3 className='text-base font-medio '>Factura</h3>
                                <p className=''><FaCircleCheck size={20} className={facturaFinalizada ? 'fill-azul' : 'fill-darkGris/30'} /></p>
                            </div>
                        )
                    }
                    <div className="flex flex-row justify-between items-center w-full">
                        <h3 className='text-base font-medio '>Términos y condiciones</h3>
                        <p className=''><FaCircleCheck size={20} className={terminos ? 'fill-azul' : 'fill-darkGris/30'}/></p>
                    </div>
                    <span className='text-xs italic font-base text-gray-500'>*Se requiere cumplir con todos los pasos para mostrar el boton de pago.</span>
                </div>
                <div className="flex flex-row justify-center items-center w-full gap-2 pt-3">
                    <input type="checkbox" name="terminoscondiciones" id="terminoscondiciones" checked={terminos} onChange={() => setTerminos(!terminos)} />
                    <span className='text-[10px] font-medio'>He leído y acepto <a target='_blank' href='/Empresa/TerminosyCondiciones' className='text-azul underline font-medio'>Términos, Condiciones y Aviso de Privacidad</a></span>
                </div>
                {
                    !requiereFactura && entrega !== "" && formaPago !== "" && terminos ?
                    <div className="w-full">
                        {
                            formaPago == "paypal" && 
                                <PayPalScriptProvider options={initialOptions}>
                                    <PayPalButtons
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                intent: "CAPTURE",
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: (totalPrice).toFixed(2),
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={async (data, actions) => {
                                            setLoadingPaypal(true);
                                            const order = await actions.order.capture();
                                            // window.location.href = "/success";
                                            localStorage.setItem("compra_mv", JSON.stringify({...order, totalPrice: totalPrice, formaPago: "Paypal"}))
                                            window.location.href = "/payment/pay?payment_id=Paypal&payment_type=Paypal&payment_method=Paypal";

                                        }}
                                        onError={(err) => {
                                            console.log(err);
                                        }}
                                    />
                                </PayPalScriptProvider>
                        }
                        {
                            formaPago == "mercadopago" &&
                            <Wallet initialization={{ preferenceId: preferencedId }} customization={{ texts:{ valueProp: 'security_details'}, visual: {buttonBackground: "#003987"}}} />
                        }
                        {
                            formaPago == "transfer" &&
                            <>
                                <button onClick={() => {
                                    setLoading(true);
                                    localStorage.setItem("compra_mv", JSON.stringify({totalPrice: totalPrice, formaPago: "Transferencia"}))
                                    setTimeout(() => {
                                        window.location.href = "/payment/pay?payment_id=Transferencia&payment_type=Transferencia&payment_method=Transferencia"
                                    }, 200);
                                }} className='bg-azul text-white py-2 px-5 rounded font-medio w-full hover:bg-azul/80 transition-all'>Finalizar Compra</button>
                                <p className='italic text-xs pt-3 text-gray-500'>La venta está sujeta a la disponibilidad de existencias al momento de confirmar el pago.</p>
                            </>
                        }
                        {
                            formaPago == "tarjeta" &&
                            <>
                                <button onClick={handlePayment} className='bg-azul text-white py-2 px-5 rounded font-medio w-full hover:bg-azul/80 transition-all'>Finalizar Compra</button>
                            </>
                        }
                    </div>
                    :
                    requiereFactura && entrega !== "" && formaPago !== "" && terminos && facturaFinalizada ?
                    <div className="w-full">
                        {
                            formaPago == "paypal" && 
                                <PayPalScriptProvider options={initialOptions}>
                                    <PayPalButtons
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                intent: "CAPTURE",
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: (totalPrice).toFixed(2),
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={async (data, actions) => {
                                            setLoadingPaypal(true);
                                            const order = await actions.order.capture();
                                            // window.location.href = "/success";
                                            localStorage.setItem("compra_mv", JSON.stringify({...order, totalPrice: totalPrice, formaPago: "Paypal"}))
                                            window.location.href = "/payment/pay?payment_id=Paypal&payment_type=Paypal&payment_method=Paypal";

                                        }}
                                        onError={(err) => {
                                            console.log(err);
                                        }}
                                    />
                                </PayPalScriptProvider>
                        }
                        {
                            formaPago == "mercadopago" &&
                            <Wallet initialization={{ preferenceId: preferencedId }} customization={{ texts:{ valueProp: 'smart_option'}}} />
                        }
                        {
                            formaPago == "transfer" &&
                            <>
                                <button onClick={() => {
                                    setLoading(true);
                                    localStorage.setItem("compra_mv", JSON.stringify({totalPrice: totalPrice, formaPago: "Transferencia"}))
                                    setTimeout(() => {
                                        window.location.href = "/payment/pay?payment_id=Transferencia&payment_type=Transferencia&payment_method=Transferencia"
                                    }, 200);
                                }} className='bg-azul text-white py-2 px-5 rounded font-medio w-full hover:bg-azul/80 transition-all'>Finalizar Compra</button>
                                <p className='italic text-xs pt-3 text-gray-500'>La venta está sujeta a la disponibilidad de existencias al momento de confirmar el pago.</p>
                            </>
                        }
                    </div>
                    :
                    null
                }
                <div className="flex flex-row justify-center items-center w-full gap-2 pt-3">
                    <MdLockOutline size={20} className='fill-dark' />
                    <p className='text-sm'>Todas nuestras transacciones son seguras</p>
                </div>
            </aside>
        </section>
        <Footer />
    </main>
  )
}

export default Checkout