import { useEffect, useState, useRef } from 'react'
import { IoSearch } from "react-icons/io5"
import { BiSolidCart } from "react-icons/bi";
import { IoMenu } from "react-icons/io5";
import { FaSortDown } from "react-icons/fa6";
import { Badge } from "@material-tailwind/react";
import { FaAngleRight } from "react-icons/fa";
import { TbLoader3 } from "react-icons/tb";
import { FaUser } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";

const Navbar = () => {

    const menuRef = useRef(null);

    const [subseccionesFilter, setSubseccionesFilter] = useState([])
    const [showMenu, setShowMenu] = useState(false)
    const [showMenuResp, setShowMenuResp] = useState(false)
    const [menuRespSelect, setShowMenuRespSelect] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const [searchResults, setSearchResults] = useState({
        firstThreeResults: [],
        unique_nom_grupo: []
    })
    const [loaderSearch, setLoaderSearch] = useState(false)
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [showLogDiv, setShowLogDiv] = useState(false)
    const [data, setData] = useState({
        categoriasRow: [],
        subseccion_ids: [],
        grupo: []
    });
    const [catRowError, setCatRowError] = useState(false);
    const [userData, setUserData] = useState({});
    const [logged, setLogged] = useState(false);

    const [totalItems, setTotalItems] = useState(0);

    const handleMenuRespToggle = () => {
        if (showMenuResp) {
          setShowMenuResp(false);
          document.getElementById("ulMenuResp").classList.add("hidden")
          setSearchResults({ firstThreeResults: [], unique_nom_grupo: [] });
        } else {
          setShowMenuResp(true);
          document.getElementById("ulMenuResp").classList.add("hidden")
          setSearchResults({ firstThreeResults: [], unique_nom_grupo: [] });
        }
      };

    const handleSearchClose = () => {
        setSearchResults({ firstThreeResults: [], unique_nom_grupo: [] });
        setSearchValue("");
    }

    const updateTotalItems = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        if (!Array.isArray(cart)) {
            return;
        }
        const totalQty = cart.reduce((acc, item) => acc + item.qty, 0);
        setTotalItems(totalQty);
    };

    useEffect(() => {
        updateTotalItems();
        const cachedData = sessionStorage.getItem('dataKeyMenuNav');
        if (cachedData) {
            setData(JSON.parse(cachedData));
        } else {
            // fetch('https://www.muebleria-villarreal.com/apimv/headerController.php')
            fetch('https://www.muebleria-villarreal.com/apimv/headerController.php')
            .then(response => response.json())
            .then(data => {
                setData(data);
                sessionStorage.setItem('dataKeyMenuNav', JSON.stringify(data));
            })
            .catch(error => {
                console.error('Error fetching data:', error)
                setCatRowError(true);
            });
        }

        const getUserData = localStorage.getItem('eyJhbGciOiJIUzI1NiJ9');
        if(getUserData) {
            setUserData(JSON.parse(getUserData));
            setLogged(true);
        }

        const handleStorageChange = () => {
            updateTotalItems();
        };
    
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('cartUpdated', handleStorageChange);
    
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('cartUpdated', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        setShowMenu(false);
        setShowSubMenu(false);
        setShowMenuResp(false);
        if (searchValue !== "" && searchValue.length > 2) {
            setSearchResults({ firstThreeResults: [], unique_nom_grupo: [] });
            setLoaderSearch(true);
            if(searchValue.length > 2) {
                fetch('https://www.muebleria-villarreal.com/apimv/searchNavController.php?search=' + searchValue, { signal })
                .then(response => response.json())
                .then(data => {
                    setSearchResults(data);
                    setLoaderSearch(false);
                    console.log(data);
                })
                .catch(error => {
                  if (error.name !== 'AbortError') {
                    console.error('Error fetching searchNav data:', error);
                  }
                });

                return () => {
                    controller.abort();
                };
            }
        } else {
            setLoaderSearch(false);
            setSearchResults({ firstThreeResults: [], unique_nom_grupo: [] });
        }
    }, [searchValue]);

    const handleLogOut = () => {
        localStorage.removeItem('eyJhbGciOiJIUzI1NiJ9');
        setUserData({});
        setLogged(false);
        window.location.href = '/';
    }

    const handleMenuSelected = (orden) => {
        if(orden == 11) {
            setShowSubMenu(false);
        } else {
            setShowSubMenu(true);
            setSubseccionesFilter(data.subseccion_ids.filter(subseccion => subseccion.orden === orden));
        }
    }

    const handleMenuRespSelected = async (orden) => {
        Array.from(document.getElementsByClassName("ulMenuRespAll")).forEach(element => {
            element.classList.add("hidden");
        });
        if(menuRespSelect !== orden) {
            setShowMenuRespSelect(orden);
            document.getElementById("ulMenuResp-" + orden).classList.remove("hidden");
            setSubseccionesFilter(data.subseccion_ids.filter(subseccion => subseccion.orden === orden));
        } else {
            document.getElementById("ulMenuResp-" + orden).classList.add("hidden");
            setShowMenuRespSelect(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          window.location.href = `/search?text=${searchValue}`;
        }
      };

  return (
    <>
        <header className='w-full px-4 py-2 flex justify-center items-center'>
            <p className='text-[10px] lg:text-sm text-azul font-medio'>Actualizamos nuestros términos y condiciones para leer más <a href='/Empresa/TerminosyCondiciones' className='underline text-link'>aqui</a></p>
        </header>
        <nav className='w-full py-3 lg:py-5 bg-azul' onMouseLeave={() => setShowLogDiv(false)}>
            <div className='w-full px-[5vw] flex flex-row justify-between items-center gap-5 py-3 lg:py-0'>
                <IoMenu size={28} stroke='white' className='lg:hidden' onClick={handleMenuRespToggle} />
                <a href="/" className='absolute lg:relative left-1/2 -translate-x-1/2 lg:left-0 lg:translate-x-0'><img src="/images/logoBlanco.png" alt="Mueblería Villarreal Logo Blanco" className='w-44 lg:w-52 -mt-2' /></a>
                <div className='w-full lg:w-7/12 relative hidden lg:block'>
                    <input type='search' placeholder='Buscar...' className='w-full py-3 pl-12 pr-5 rounded-full text-sm relative z-0' value={searchValue} onChange={(e) => {
                        let value = e.target.value.replace(/[^a-zA-Z0-9áéíóúÁÉÍÓÚ ]/g, '');
                        value = value.startsWith(' ') ? value.trimStart() : value;
                        if (value.length <= 100) {
                            setSearchValue(value);
                            setShowMenu(false);
                        }
                    }} onKeyDown={handleKeyDown} ></input>
                    <IoSearch className="absolute left-4 top-3 z-50 fill-dark" size={22} />
                    {
                        loaderSearch && (
                            <div className='absolute top-12 rounded-xl left-0 w-full bg-white z-[99] shadow-md p-3 h-24 flex justify-center items-center'>
                                <TbLoader3 className='animate-spin stroke-azul' size={40} />
                            </div>
                        )
                    }
                    {
                        searchResults.firstThreeResults !== "N/A" ? 
                        searchResults.firstThreeResults.length > 0 && searchValue.length > 2 && (
                            <>
                                <div className='w-full h-svh bg-transparent fixed top-0 left-0 z-[98]' onClick={handleSearchClose}></div>
                                <div className='absolute top-12 grid grid-cols-3 rounded-xl left-0 w-full bg-white z-[99] shadow-md overflow-hidden'>
                                    <ul className=' bg-gray-300 h-full p-5'>
                                        <li className='uppercase font-negro text-azul pb-2'>Categorias Recomendadas:</li>
                                        {
                                            searchResults.unique_nom_grupo.map((grupo, index) => (
                                                <li key={index} className='text-sm py-1'><a className='text-xs uppercase' href={"/Departamento/" + grupo.departamento + "/" + grupo.grupo}>{grupo.nom_grupo}</a></li>
                                            ))
                                        }
                                    </ul>
                                    <ul className='col-span-2'>
                                        {
                                            searchResults.firstThreeResults.map((product, index) => (
                                                <li key={index} className='flex flex-row justify-start items-center cursor-pointer gap-5 p-3 hover:bg-gray-200' onClick={() => window.location.href = "/Articulo/" + product.articulo + "/" + product.grupo}>
                                                    <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + product.departamento + "/" + product.articulo + ".jpg?quality=auto&format=webp"} alt={product.nom_producto} className='w-16 h-16 mix-blend-darken object-cover' />
                                                    <div className='flex flex-col justify-start items-start'>
                                                        <p className='text-sm'>{product.descripcion_corta}</p>
                                                        <div className="flex flex-row justify-start items-center gap-3 pt-3">
                                                            <span className='text-gris text-xs cross line-through'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(product.precio_lista)}</span>
                                                            <span className='text-rojo text-base cross font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(product.precio_contado)}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        <li className='text-sm p-3 hover:bg-gray-200 flex justify-center items-center'><a href={"/search?text=" + searchValue} className='text-center uppercase text-xs text-azul font-medio'>Ver más productos relacionados</a></li>
                                    </ul>
                                </div>
                            </>
                        )
                        :
                        <div className='absolute top-12 grid grid-cols-3 rounded-xl left-0 w-full bg-white z-50 shadow-md overflow-hidden'>
                            <ul className=' bg-gray-300 h-full p-5'>
                                <li className='uppercase font-negro text-azul pb-2'>Categorias Recomendadas:</li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/15/132">Colchones</a></li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/17/206">Tablets</a></li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/2/22">Comedores</a></li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/1/55">Salas</a></li>
                            </ul>
                            <ul className='col-span-2'>
                                <li className='flex flex-row justify-start items-center cursor-pointer gap-1 p-3 hover:bg-gray-200 min-w-0'>
                                    <span className='truncate'>No encontramos resultados para <b className='text-azul break-words whitespace-normal'>{searchValue}</b></span>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <div className='flex flex-row justify-end items-center gap-14 relative'>
                    {
                        logged ? (
                            <>
                                <a href="/cliente" className='text-white hidden lg:block' onMouseEnter={() => setShowLogDiv(true)}>Hola, {userData.nombre}</a>
                                <div onMouseLeave={() => setShowLogDiv(false)} className={`bg-white absolute text-dark top-10 left-0 flex-col justify-center items-center rounded-lg shadow z-30 overflow-hidden ${showLogDiv ? "flex" : "hidden"}`}>
                                    <button onClick={() => window.location.href = "/cliente"} className='w-full text-center text-sm px-6 py-3 transition-all hover:bg-azul hover:text-white'>Mi Cuenta</button>
                                    {/* <button className='w-full text-center text-sm px-6 py-3 transition-all hover:bg-azul hover:text-white'>Monedero Electrónico</button> */}
                                    <button onClick={handleLogOut} className='w-full text-center text-sm px-6 py-3 transition-all hover:bg-rojo hover:text-white'>Cerrar Sesión</button>
                                </div>
                            </>
                        ) : (
                            <a href="/login" className='text-white hidden lg:block'>Inicia sesión</a>
                        )
                    }
                    <div className='flex justify-end items-center gap-2'>
                        <FaUserCircle className='fill-white cursor-pointer size-6 lg:hidden' onClick={() => {
                            if(logged) {
                                window.location.href = "/cliente";
                            } else {
                                window.location.href = "/login";
                            }
                        }} />
                        <Badge content={totalItems != 0 ? totalItems : "0"} className='text-[11px] max-h-4 max-w-4 cursor-pointer' onClick={() => window.location.href = "/carrito"}>
                            <BiSolidCart className='fill-white cursor-pointer size-7'  onClick={() => window.location.href = "/carrito"}/>
                        </Badge>
                    </div>
                </div>
            </div>
            <div className='w-full lg:w-1/2 relative block lg:hidden px-4'>
                <input type='search' placeholder='Buscar...' className='w-full py-3 pl-10 pr-5 rounded-md text-sm relative z-0' value={searchValue} onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z0-9áéíóúÁÉÍÓÚ ]/g, '');
                    value = value.startsWith(' ') ? value.trimStart() : value;
                    setShowMenuResp(false);
                    if (value.length <= 100) {
                      setSearchValue(value);
                    }
                }} onKeyDown={handleKeyDown}></input>
                <IoSearch className="absolute left-7 top-3 fill-dark" size={22} />
                {
                        loaderSearch && (
                            <div className='absolute top-12 rounded-xl left-0 w-full bg-white z-[99] shadow-md p-3 h-24 flex justify-center items-center'>
                                <TbLoader3 className='animate-spin stroke-azul' size={40} />
                            </div>
                        )
                    }
                    {
                        searchResults.firstThreeResults !== "N/A" ?
                        searchResults.firstThreeResults.length > 0 && searchValue.length > 2 && (
                            <>
                                <div className='w-full h-svh bg-transparent fixed top-0 left-0 z-[98]' onClick={handleSearchClose}></div>
                                <div className='absolute top-12 grid grid-cols-1 rounded-xl left-0 w-full bg-white z-[99] shadow-md overflow-hidden'>
                                    <ul className=' bg-gray-300 h-full p-5'>
                                        <li className='uppercase font-negro text-azul pb-2 text-center'>Categorias Recomendadas:</li>
                                        {
                                            searchResults.unique_nom_grupo.map((grupo, index) => (
                                                <li key={index} className='text-sm py-1 flex justify-center items-center'><a className='text-xs uppercase' href={"/Departamento/" + grupo.departamento + "/" + grupo.grupo}>{grupo.nom_grupo}</a></li>
                                            ))
                                        }
                                    </ul>
                                    <ul className='col-span-2'>
                                        {
                                            searchResults.firstThreeResults.map((product, index) => (
                                                <li key={index} className='flex flex-row justify-start items-center cursor-pointer gap-5 p-3 hover:bg-gray-200' onClick={() => window.location.href = "/Articulo/" + product.articulo + "/" + product.grupo}>
                                                    <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + product.departamento + "/" + product.articulo + ".jpg?quality=auto&format=webp"} alt={product.nom_producto} className='w-16 h-16 mix-blend-darken object-cover' />
                                                    <div className='flex flex-col justify-start items-start'>
                                                        <p className='text-sm'>{product.descripcion_corta}</p>
                                                        <div className="flex flex-row justify-start items-center gap-3 pt-3">
                                                            <span className='text-gris text-xs cross line-through'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(product.precio_lista)}</span>
                                                            <span className='text-rojo text-base cross font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(product.precio_contado)}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        <li className='text-sm p-3 hover:bg-gray-200 flex justify-center items-center'><a href={"/search?text=" + searchValue} className='text-center uppercase text-xs text-azul font-medio'>Ver más productos relacionados</a></li>
                                    </ul>
                                </div>
                            </>
                        )
                        :
                        <div className='absolute top-12 grid grid-cols-1 rounded-xl left-0 w-full bg-white z-50 shadow-md overflow-hidden'>
                            <ul className=' bg-gray-300 h-full p-5'>
                                <li className='uppercase font-negro text-azul pb-2'>Categorias Recomendadas:</li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/15/132">Colchones</a></li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/17/206">Tablets</a></li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/2/22">Comedores</a></li>
                                <li className='text-sm py-1'><a className='text-xs uppercase' href="/Departamento/1/55">Salas</a></li>
                            </ul>
                            <ul className='col-span-2'>
                                <li className='flex flex-row justify-start items-center cursor-pointer gap-1 p-3 hover:bg-gray-200 text-sm'>No encontramos resultados para <b className='text-azul'>{searchValue}</b></li>
                            </ul>
                        </div>

                    }
            </div>
        </nav>
        <div className='w-full px-[5vw] py-4 hidden lg:flex flex-row justify-between items-center gap-5' onMouseLeave={() => {
            setShowMenu(false)
            setShowSubMenu(false)
        }}>
            <div className='flex flex-row justify-start items-center gap-8 relative'>
                <button className='text-dark hidden lg:flex items-center gap-1' onMouseEnter={() => {
                    setShowMenu(true);
                    setSearchResults({ firstThreeResults: [], unique_nom_grupo: [] });
                }}>Departamentos <FaSortDown fill='black' className='-mt-1.5' size={12} /></button>
                <a href="/mesaregalos" className='text-dark hidden lg:block'>Mesa de Regalos</a>
                {
                    showMenu && (
                        <>
                            <div className='absolute top-9 z-[51] bg-white min-w-48 shadow-xl'>
                                <ul>
                                    {
                                        data.categoriasRow.length > 0 ? data.categoriasRow.map((product, index) => {
                                            if(product.nom_seccion === "Relojes") {
                                                return(<li key={index}>
                                                    <a href='/Departamento/20/81' className='transition-all hover:text-white hover:bg-azul flex flex-row justify-between items-center text-sm p-3 cursor-pointer group' onMouseEnter={() => handleMenuSelected(product.orden)}>{product.nom_seccion}</a>
                                                </li>)
                                            } else {
                                                return(<li key={index}>
                                                    <p className='transition-all hover:text-white hover:bg-azul flex flex-row justify-between items-center text-sm p-3 cursor-pointer group' onMouseEnter={() => handleMenuSelected(product.orden)}>{product.nom_seccion} <FaAngleRight className='fill-dark group-hover:fill-white transition-all' /></p>
                                                </li>)
                                            }
                                        })
                                        :
                                        <li>
                                            {
                                                catRowError ? 
                                                <p className='flex flex-row justify-between items-center text-sm p-3 cursor-pointer w-full'>Favor de intentar nuevamente</p>
                                                :
                                                <p className='flex flex-row justify-between items-center text-sm p-3 cursor-pointer w-full'>Cargando menu...</p>
                                            }
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className={`absolute top-9 left-48 min-w-[560px] z-50 min-h-[616px] max-h-[616px] overflow-y-auto bg-white flex-col justify-start items-start shadow-xl ${showSubMenu ? "flex" : "hidden"}`} id='submenuDiv'>
                                {
                                    subseccionesFilter.length > 0 && subseccionesFilter.map((subseccion, index) => (
                                        <div key={index} className='flex flex-col justify-start items-start gap-3 p-3 w-full'>
                                            <h4 className='text-sm text-white font-medio uppercase bg-azul px-4 py-2 w-full text-center'>{subseccion.nom_subseccion}</h4>
                                            <div className='w-full grid grid-cols-2 gap-2'>
                                                {
                                                    data.grupo.length > 0 && data.grupo.filter(grupo => grupo.subseccion_id === subseccion.subseccion_id).map((grupo, index) => (
                                                        <a href={"/Departamento/"+ grupo.subseccion_id +"/" + grupo.grupo} className='text-sm w-full capitalize' key={index}>{grupo.nom_grupo}</a>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </div>
            <div className='flex flex-row justify-end items-center gap-8'>
                <a href="/tramitar-credito" className='text-dark hidden lg:block'>TRAMITA TU CRÉDITO</a>
                <a href="/Empresa/PreguntasFrecuentes" className='text-dark hidden lg:block'>Ayuda</a>
                <a href="/Empresa/Sucursales" className='text-dark hidden lg:block'>Sucursales</a>
            </div>
        </div>
        {
            showMenuResp && <div className='w-full h-svh bg-transparent fixed top-0 left-0 z-20' onClick={handleMenuRespToggle}></div>
        }
        <div ref={menuRef} className={`w-full bg-white lg:hidden absolute z-40 transition-all shadow-lg flex flex-col justify-start items-start gap-3 px-5 overflow-hidden ${showMenuResp === true ? "h-fit py-5" : "h-0 py-0"}`}>
            {
                logged &&
                <p className='text-base'>Hola, {userData.nombre}</p>
            }
            <p onClick={() => {
                document.getElementById("ulMenuResp").classList.toggle("hidden")
            }} className='text-base flex justify-start items-center gap-2'>Departamentos <FaSortDown fill='black' className='-mt-1.5' size={12} /></p>
            <ul id="ulMenuResp" className='w-full hidden transition-all'>
            {
                data.categoriasRow.length > 0 ? data.categoriasRow.map((product, index) => {
                    if(product.nom_seccion === "Relojes") {
                        return (
                            <li key={index}>
                                <a href='/Departamento/20/81' className='transition-all hover:text-white hover:bg-azul flex flex-row justify-between items-center text-sm p-3 cursor-pointer group' onClick={() => handleMenuRespSelected(product.orden)}>{product.nom_seccion}</a>
                            </li>
                        )
                    } else {
                        return (
                            <>
                                <li key={index}>
                                    <p className='transition-all hover:text-white hover:bg-azul flex flex-row justify-between items-center text-sm p-3 cursor-pointer group' onClick={() => handleMenuRespSelected(product.orden)}>{product.nom_seccion} <FaAngleRight className='fill-dark group-hover:fill-white transition-all' /></p>
                                </li>
                                <li id={"ulMenuResp-" + product.orden} className='hidden ulMenuRespAll'>
                                    {
                                        subseccionesFilter.length > 0 && subseccionesFilter.map((subseccion, index) => (
                                            <div key={index} className='flex flex-col justify-start items-start gap-3 p-3 w-full'>
                                                <h4 className='text-sm text-white font-medio uppercase bg-azul px-4 py-2 w-full text-center'>{subseccion.nom_subseccion}</h4>
                                                <div className='w-full grid grid-cols-2 gap-2'>
                                                    {
                                                        data.grupo.length > 0 && data.grupo.filter(grupo => grupo.subseccion_id === subseccion.subseccion_id).map((grupo, index) => (
                                                            <a href={"/Departamento/"+ grupo.subseccion_id +"/" + grupo.grupo} className='text-sm w-full capitalize' key={index}>{grupo.nom_grupo}</a>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </li>
                            </>
                        )
                    }
                })
                :
                <li>
                    <p className='flex flex-row justify-between items-center text-sm p-3 cursor-pointer w-full'>Cargando menu...</p>
                </li>
            }
            </ul>
            <a href="/mesaregalos" className='text-base'>Mesa de Regalos</a>
            <a href="/tramitar-credito" className='text-base'>Tramita tu Crédito</a>
            <a href="/Empresa/PreguntasFrecuentes" className='text-base'>Ayuda</a>
            <a href="/Empresa/Sucursales" className='text-base'>Sucursales</a>
            {
                logged ? 
                <>
                    <a href="#" className='text-base'>Mi Cuenta</a>
                    <button onClick={handleLogOut} className='text-base'>Cerrar Sesión</button>
                </>
                :
                <>
                    <a href="/login" className='text-base'>Iniciar Sesión</a>
                    <a href="/UserRegistrationForm" className='text-base'>Crear Cuenta</a>
                </>
    }
        </div>
    </>
  )
}

export default Navbar